<template>
  <div class="top-trader">
    <div class="trading-section">
      <div class="row text-center">
        <div class="col-sm-12 my-4 my-md-5">
          <div class="hr dec-color" />
          <p class="title-section">{{ $t('TopAgency.163') }}</p>
          <div class="hr dec-color" />
        </div>
        <div class="col-lg-12 col-xs-12">
          <div class="section-content">
            <h3 class="text-sub">
              {{ $t('TopAgency.164') }}
              <br />{{ $t('TopAgency.165') }}
              <span class="text-color-yellow">
                {{ $t('TopAgency.166') }}
              </span>
            </h3>
            <div class="box">
              <div class="table-responsive" style="max-height: 400px;">
                <table
                  class="table table-vcenter table-bordered table-hover table-striped table-condensed"
                >
                  <thead>
                    <tr>
                      <th>{{ $t('TopAgency.167') }}</th>
                      <th>ID</th>
                      <th>{{ $t('TopAgency.168') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in ListTopLeader" :key="list.id">
                      <td style="color:#0CA300">
                        <img
                          :src="
                            require(`@/assets/images/promotion/lv-${list.id}.png`)
                          "
                          v-if="list.id <= 3"
                          width="40"
                        />
                        <span v-else>{{ list.id }}</span>
                      </td>
                      <td>
                        <span v-if="list.id <= 3"
                          ><b>{{ list.userID }}</b></span
                        >
                        <span v-else>{{ list.userID }}</span>
                      </td>
                      <td>
                        <span v-if="list.id <= 3"
                          ><b>{{ list.ib }}</b></span
                        >
                        <span v-else>{{ list.ib }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trading-section">
      <div class="row text-center">
        <!-- <div class="col-sm-12">
                <div class="heading">
                    <span class="img-light">
                        <img class="top" :src="require('@/assets/images/promotion/light.png')">
                        <img class="bottom" :src="require('@/assets/images/promotion/light.png')">
                    </span>
                    <img :src="require('@/assets/images/promotion/trading-heading2.png')">
                </div>
            </div> -->
        <div class="col-lg-12 col-xs-12">
          <div class="section-content">
            <h2 class="title-sub">{{ $t('TopAgency.169') }}</h2>
            <h3 class="text-sub">
              {{ $t('TopAgency.170') }}
              <span class="text-color-yellow">{{ $t('TopAgency.171') }}</span>
            </h3>
            <div class="box">
              <div class="table-responsive box2" style="max-height: 400px;">
                <table
                  class="table table-vcenter table-bordered table-hover table-striped table-condensed"
                >
                  <thead>
                    <tr>
                      <th>{{ $t('TopAgency.167') }}</th>
                      <th>ID</th>
                      <th>{{ $t('TopAgency.168') }}</th>
                      <th>{{ $t('TopAgency.172') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="color:#0CA300">01</td>
                      <td>{{ InfoTopLeader.userID }}</td>
                      <td>${{ InfoTopLeader.ib }}</td>
                      <td>
                        <input
                          type="checkbox"
                          id="cb1"
                          v-if="InfoTopLeader.status == 1"
                          checked
                          readonly
                        />
                        <input
                          type=""
                          v-else
                          readonly
                          style="width: 18px;height: 18px;border: 2px #007308 solid;"
                        />
                        <label for="cb1"></label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="box-desc">
                <h3>{{ $t('TopAgency.173') }}:</h3>
                <ul>
                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i> {{ $t('TopAgency.174') }} <span class="text-color-yellow">{{ $t('TopAgency.175') }}</span> {{ $t('TopAgency.176') }}
                  </li>

                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i>{{ $t('TopAgency.177') }}
                    <span class="text-color-yellow">$10,000</span>
                  </li>

                  <!-- <li>
                    <i class="fa fa-circle" aria-hidden="true"></i>Personal
                    transaction volume/month must reach
                    <span class="text-color-yellow">$3.000</span>
                  </li> -->

                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i>{{ $t('TopAgency.178') }}
                  <span class="text-color-yellow">
                    20 {{ $t('TopAgency.179') }}
                  </span>
                  {{ $t('TopAgency.180') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
        </div>
      </div>
    </div>
    <div class="trading-section">
      <div class="row text-center">
        <div class="col-sm-12">
          <div class="heading">
            <span class="img-light">
              <img
                class="top"
                :src="require('@/assets/images/promotion/light.png')"
              />
              <img
                class="bottom"
                :src="require('@/assets/images/promotion/light.png')"
              />
            </span>
            <img
              :src="require('@/assets/images/promotion/trading-heading3.png')"
            />
          </div>
        </div>
        <div class="col-lg-12 col-xs-12">
          <div class="section-content">
            <div class="box-title">
              <span class="light">
                <img
                  class="bottom"
                  :src="require('@/assets/images/promotion/light.png')"
                />
              </span>
              <span>
                {{ $t('TopAgency.167') }}:<br />
                <span class="text-color-yellow" style="font-weight:800">
                  {{ $t('MainContent.122') }}
                </span>
              </span>
            </div>
            <div class="box-desc">
              <h3 class="text-white">
                {{ $t('TopAgency.182') }}:
              </h3>
              <ul style="text-transform:uppercase;">
                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i> {{ $t('TopAgency.174') }} <span class="text-color-yellow">{{ $t('TopAgency.175') }}</span> {{ $t('TopAgency.176') }}
                </li>

                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i>{{ $t('TopAgency.177') }}
                  <span class="text-color-yellow" style="font-weight:900">
                    $10,000</span
                  >
                </li>
                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i>{{ $t('TopAgency.178') }}
                  <span class="text-color-yellow" style="font-weight:900">
                    20 {{ $t('TopAgency.179') }}
                  </span>
                  {{ $t('TopAgency.180') }}
                </li>
              </ul>
              <h3 class="text-white">
                {{ $t('TopAgency.183') }}
                <span class="text-color-yellow" style="font-weight:900">
                  {{$t('Message.267')}} 28
                </span>
              </h3>
            </div>
          </div>
          <div class="btn-reward">
            <button @click="modalShowReward()">
              <img src="~@/assets/images/promotion/btn-reward.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="showReward"
      hide-footer
      hide-header
      body-class="p-0 h-100 d-flex"
      header-class="p-0"
      modal-class="d-flex justify-content-center  align-items-center"
    >
      <div class="close-re" @click="$bvModal.hide('showReward')">x</div>

      <div class="box-item-reward">
        <div class="titl-pop">
          <i
            >{{ $t('TopAgency.184') }}:</i
          >
        </div>
        <div class="img-content">
          <img
            class="top1"
            :src="require(`@/assets/images/promotion/1500.png`)"
            alt=""
          />
        </div>
        <div class="img-content">
          <img
            v-for="top in arraytop"
            :key="`topimg_${top}`"
            :class="`top${top}`"
            :src="require(`@/assets/images/promotion/${top}.png`)"
            alt=""
          />
        </div>

        <div class="okey-button">
          <button
            @click="$bvModal.hide('showReward')"
            type="button"
            class="button button-normal mt-3"
          >
            <div class="button-inner">{{ $t('TopAgency.185') }}</div>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    passwordCurrent: '',
    arraytop: [750, 500, 200],
  }),
  computed: {
    ...mapGetters({
      InfoTopLeader: 'promotion/InfoTopLeader',
      ListTopLeader: 'promotion/ListTopLeader',
    }),
  },
  mounted() {},
  created() {
    this.$store.dispatch('promotion/req_getInfoTopLeader');
    this.$store.dispatch('promotion/req_getListTopLeader');
  },
  beforeDestroy() {
    // this.unsubscribe();
  },
  methods: {
    modalShowReward() {
      this.$bvModal.show('showReward');
    },
    HideModalNoti() {
      this.$bvModal.hide('showReward');
    },
  },
};
</script>
<style lang="scss">
#showReward {
  padding-left: 0px !important;
  .modal-dialog {
    width: 100%;
    padding: 10px;
    min-height: 300px;
    max-width: 800px;
    .modal-content {
      min-height: 300px;
      width: 100%;
      background: transparent !important;
      background-image: unset !important;
      box-shadow: unset !important;
      border: 0 none !important;
      display: flex;
      align-items: center;
      .modal-body {
        background: linear-gradient(45deg, #0bb4e4, #29f366) !important;
        padding: 1px !important;
        border-radius: 15px;
        height: 100% !important;
        max-height: 100% !important;
      }
      .box-item-reward {
        height: 100%;
        border-radius: 20px;
        width: 100%;
        background: url('~@/assets/images/promotion/br popup.png') #000;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        .titl-pop {
          font-size: 25px;
          text-align: center;
          max-width: 90%;
          margin: auto;
          background: linear-gradient(
            90deg,
            #c8992e 0%,
            #b57e10 15.0732%,
            #f9df7b 42.9829%,
            #fff3a6 59.0896%,
            #f9df7b 72.6447%,
            #b57e10 92.5734%,
            #cda035 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: 'Montserrat-ExtraBold';
          text-transform: uppercase;
          -webkit-text-stroke-width: 0.25px;
          -webkit-text-stroke-color: rgb(255, 255, 255);
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
          line-height: 1.3;
          padding-top: 10px;
          @media (max-width: 480px) {
            padding-top: 40px;
          }
        }
        div.img-content {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          img {
            height: 200px;
            width: 200px;
            margin: 10px;
            &.top1 {
              height: 250px;
              width: 250px;
            }
          }
        }
        .okey-button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 5px 30px 5px;
          .button {
            border: none;
            width: 12.5rem;
            height: 50px;
            margin: 0px;
            color: rgb(245, 246, 247);
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            background-color: rgb(88, 26, 196);
            background-image: conic-gradient(
              from 1turn,
              rgb(88, 26, 196),
              rgb(119, 60, 253)
            );
            border-radius: 6.25rem;
            font-weight: bold;
            cursor: pointer;
            @media (max-width: 480px) {
              width: 10rem;
            }
          }
        }
      }
    }
  }
  .modal-body {
    position: relative;
    .close-re {
      position: absolute;
      top: 5px;
      right: 10px;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
<style scoped lang="scss">
.title-gradiant {
  font-size: 50px;
  background: linear-gradient(to right, #0bb4e4, #29f366);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Montserrat-ExtraBold';
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffff;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}
.title-section {
  color: #ffff;
  font-size: 50px;
  text-transform: uppercase;
  font-family: 'Montserrat-ExtraBold';
  align-items: center;
  text-align: center;
  -webkit-text-stroke-color: black;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
}
.hr.dec-color {
  background: #ffff;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  max-width: 450px;
}
.hr.dec-color2 {
  background: linear-gradient(to right, #0bb4e4, #29f366);
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  max-width: 500px;
}
.hr.dec-color3 {
  background: linear-gradient(to right, #0bb4e4, #29f366);
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1.5px;
  max-width: 450px;
}
input[id='cb1'] + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #007308;
  border-radius: 2px;
  cursor: pointer;
  margin: 5px 0 0;
  text-align: center;
}
input[id='cb1']:checked + label:after {
  position: relative;
  top: -4px;
  left: 0px;
  content: '\2714';
  font-size: 14px;
}
input[id='cb1'] {
  display: none;
}
.title {
  text-align: right;
  background: #fff0;
  padding: 0;
  font-size: 15px;
  font-weight: 700;
  color: #007308;
}
#page-content {
  padding: 0 !important;
  position: relative;
}
.btn-reward {
  margin: auto;
  max-width: 350px;
  position: relative;
  text-align: center;
}
.vm--modal {
  background: #fff0;
  padding: 0;
  max-width: 600px !important;
}
.img-reward {
  width: 100%;
}
.btn-reward button {
  background: #fff0;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 100%;
  margin: auto;
}
.btn-reward button img {
  width: 180px;
}
.box-title {
  width: fit-content;
  margin: auto;
  padding: 5px 25px;
  border: 2px #007308 solid;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  position: relative;
}

.box-title span {
  position: relative;
  z-index: 2;
}
.box-title .light {
  position: absolute;
  width: 120%;
  bottom: 0px;
  left: -15%;
  z-index: 1;
}
.box-title .light img {
  width: 100%;
}
.table-responsive {
  margin: 0;
}
.text-white {
  color: #fff !important;
}
.line-bottom {
  display: inline-block;
  margin: 0 2px 30px;
  height: 2px;
  width: 30px;
  background: rgb(255 255 255 / 0.7);
}
.section-content {
  padding: 40px 0 25px;
}
.section-content .title-sub {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  line-height: 1.4;
  max-width: 700px;
  margin: 0 auto 20px;
}
.section-content .text-sub {
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  color: #fff;
  line-height: 1.35;
  max-width: 700px;
  margin: auto;
}
.box-desc {
  width: 80%;
  max-width: max-content;
  margin: 25px auto;
  text-align: left;
}
.box-desc h3 {
  color: transparent;
  background: #007308;
  font-family: 'Orbitron', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 900;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.5px white;
  filter: drop-shadow(0 0 3px rgb(0, 0, 0, 0.3));
}
.box-desc ul {
  list-style: none;
  margin-bottom: 0;
}
.box-desc ul li {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}
.box-desc ul li svg {
  color: #fffa94;
  font-size: 14px;
  padding-right: 10px;
}
.section-content .box {
  border: 2px #77b61d solid;
  border-radius: 10px;
  max-width: 900px;
  font-family: sans-serif;
  margin: auto;
  color: #fff;
  background: #1e2024;
  padding: 15px;
  .table-responsive {
    padding: 0px 10px 0px 0px;
  }
  @media (min-width: 1024px) {
    .table-responsive {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  @media (max-width: 767px) {
    padding: 5px;
  }
}
.section-content .box .box2 {
  border: 2px #fff solid;
  border-radius: 10px;
  max-width: 800px;
  font-family: sans-serif;
  margin: auto;
  color: #fff;
  background: #282627;
  margin: 0px auto;
  padding: 15px 30px;
}
.table-responsive {
  max-height: 500px;
  overflow: auto;
}
.text-color-yellow {
  color: #fffa94 !important;
  font-weight: 600;
}
.section-content .box .table thead > tr > th,
.section-content .box .table tbody > tr > th,
.section-content .box .table tfoot > tr > th,
.section-content .box .table thead > tr > td,
.section-content .box .table tbody > tr > td,
.section-content .box .table tfoot > tr > td,
.section-content .box .table tbody + tbody,
.section-content .box .table-bordered,
.section-content .box .table-bordered > thead > tr > th,
.section-content .box .table-bordered > tbody > tr > th,
.section-content .box .table-bordered > tfoot > tr > th,
.section-content .box .table-bordered > thead > tr > td,
.section-content .box .table-bordered > tbody > tr > td,
.section-content .box .table-bordered > tfoot > tr > td {
  border: 2px #fff0 solid !important;
  margin: 2px !important;
  vertical-align: middle;
}
.trading-section .heading {
  max-width: 600px;
  margin: auto;
  position: relative;
  text-align: center;
}
.trading-section .heading .img-light img {
  position: absolute;
  width: 90%;
  min-width: 70px;
}
.trading-section .heading .img-light img.bottom {
  bottom: -55%;
  left: -15%;
  z-index: 1;
}
.trading-section .heading .img-light img.top {
  top: -55%;
  right: -15%;
  z-index: 1;
}
.trading-section .heading img {
  width: auto;
  position: relative;
  z-index: 2;
  height: 25px;
  @media (max-width: 400px) {
    width: 100%;
    height: auto;
  }
}
.section-content .box .table {
  border-collapse: inherit;
  border-spacing: 3px;
  color: #fff;
}
.section-content .box .box2 .table {
  border-spacing: 7px;
}
.section-content .box .table thead > tr > th {
  background: #5da000;
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  vertical-align: middle;
}
.section-content .box .table-striped > tbody > tr {
  background: transparent;
  color: #dfdfe2;
  font-size: 15px;
  font-weight: 500;
  td {
    padding: 0;
    span {
      height: 50px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.section-content .box .table-striped > tbody > tr:nth-of-type(odd) {
  color: #b9b5b5;
  background-color: rgba(90, 84, 84, 0.288);
  font-size: 15px;
  font-weight: 500;
}
.trading-section {
  background-position: center center;
  background-size: cover;
  padding: 60px 20px 30px;
  background-repeat: no-repeat;
  @media (max-width: 575px) {
    padding: 0px 20px 30px;
  }
}
.section-content .box .box2 .table th,
.section-content .box .box2 .table td {
  width: 20%;
}
.bg-trading1 {
  background-image: url('~@/assets/images/promotion/bg_trading_secion1-min.png');
}
.bg-trading2 {
  background-image: url('~@/assets/images/promotion/bg_trading_secion2-min.png');
}
.bg-trading3 {
  background-image: url('~@/assets/images/promotion/bg_trading_secion3-min.png');
}
.text-center {
  text-align: center !important;
}
.btn-group > .btn {
  float: none;
}
.justify-content-center {
  justify-content: center !important;
}
.font-medium,
.text-theme-11 {
  text-align: left;
  color: #fff;
  padding: 0 15px;
  margin-bottom: 0px;
}
.text-theme-11 {
  color: yellow;
}
.upload_images {
  width: calc(100% - 30px);
  height: 160px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: #daf2fdb3 !important;
  border-radius: 10px;
  margin: 0 15px;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.widget {
  background-color: rgb(255 255 255 / 0.25);
}
.show_images {
  width: calc(100% - 30px);
  height: 200px;
  position: relative;
  padding: 20px;
  margin: 0 15px;
  text-align: center;
  background: #daf2fdb3 !important;
}

.show_images img {
  height: 100%;
  width: auto;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images button {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  background: gold;
  border: none;
  right: 0;
  padding: 5px 15px;
  bottom: 0;
  margin: auto;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images:hover {
  background: #056996b3 !important;
}

.show_images:hover img {
  opacity: 0.5;
}

.show_images:hover button {
  opacity: 1;
}

.upload_file_1 {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload_file_1 + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Rubik', sans-serif;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  padding: 10px 49px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
}

@media screen and (max-width: 575px) {
  .box-desc ul {
    padding-left: 0;
  }
}
.upload_file_1 + label.btn-2 {
  background-color: #f78b00;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 10px -4px rgb(0 0 0 / 0.7);
}
.upload_file_1 + label.btn-2::before {
  color: #fff;
  content: '\f382';
  font-family: 'Font Awesome 5 Pro';
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.upload_file_1 + label.btn-2:hover {
  background-color: #fbc500;
}
.upload_file_1 + label.btn-2:hover::before {
  right: 75%;
}
.icon {
  width: 80px;
  height: 80px;
  margin: auto;
  background: #c8973b;
  line-height: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #fff;
}
.mr-5 {
  margin-right: 5px !important;
}
.vm--modal {
  overflow-y: auto;
}
.profile h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.bg-theme-11 {
  margin: 0;
  height: 47px;
  display: block;
  line-height: 0;
  padding: 10px;
  background: #3ab32a;
  color: #fff;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.col-4 {
  width: 33.33333333%;
  float: left;
}
.col-8 {
  width: 66.6666667%;
  float: left;
}
</style>
